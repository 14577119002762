/**
 * @license
 * Copyright 2017 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export abstract class DbInterface {
 private dbPromise: Promise | null = null;

 protected abstract readonly dbName: string;
 protected abstract readonly dbVersion: number;
 protected abstract readonly objectStoreName: string;

 /**
 * Database initialization.
 *
 * This function should create and update object stores.
 */
 protected abstract onDbUpgrade(
 request: IDBOpenDBRequest,
 event: IDBVersionChangeEvent
 ): void;

 /** Gets record(s) from the objectStore that match the given key. */
 get(key: IDBValidKey): Promise {
 return this.createTransaction(objectStore => objectStore.get(key));
 }

 /** Gets record(s) from the objectStore that match the given index. */
 getIndex(index: string, key: IDBValidKey): Promise {
 function runRequest(objectStore: IDBObjectStore): IDBRequest {
 const idbIndex = objectStore.index(index);
 return idbIndex.get(key);
 }

 return this.createTransaction(runRequest);
 }

 /** Assigns or overwrites the record for the given value. */
 // IndexedDB values are of type "any"
 put(value: unknown): Promise {
 return this.createTransaction(
 objectStore => objectStore.put(value),
 'readwrite'
 );
 }

 /** Deletes record(s) from the objectStore that match the given key. */
 delete(key: IDBValidKey | IDBKeyRange): Promise {
 return this.createTransaction(
 objectStore => objectStore.delete(key),
 'readwrite'
 );
 }

 /**
 * Close the currently open database.
 */
 async closeDatabase(): Promise {
 if (this.dbPromise) {
 const db = await this.dbPromise;
 db.close();
 this.dbPromise = null;
 }
 }

 /**
 * Creates an IndexedDB Transaction and passes its objectStore to the
 * runRequest function, which runs the database request.
 *
 * @return Promise that resolves with the result of the runRequest function
 */
 private async createTransaction(
 runRequest: (objectStore: IDBObjectStore) => IDBRequest,
 mode: 'readonly' | 'readwrite' = 'readonly'
 ): Promise {
 const db = await this.getDb();
 const transaction = db.transaction(this.objectStoreName, mode);
 const request = transaction.objectStore(this.objectStoreName);
 const result = await promisify(runRequest(request));

 return new Promise((resolve, reject) => {
 transaction.oncomplete = () => {
 resolve(result);
 };
 transaction.onerror = () => {
 reject(transaction.error);
 };
 });
 }

 /** Gets the cached db connection or opens a new one. */
 private getDb(): Promise {
 if (!this.dbPromise) {
 this.dbPromise = new Promise((resolve, reject) => {
 const request = indexedDB.open(this.dbName, this.dbVersion);
 request.onsuccess = () => {
 resolve(request.result);
 };
 request.onerror = () => {
 this.dbPromise = null;
 reject(request.error);
 };
 request.onupgradeneeded = event => this.onDbUpgrade(request, event);
 });
 }

 return this.dbPromise;
 }
}

/** Promisifies an IDBRequest. Resolves with the IDBRequest's result. */
function promisify(request: IDBRequest): Promise {
 return new Promise((resolve, reject) => {
 request.onsuccess = () => {
 resolve(request.result);
 };
 request.onerror = () => {
 reject(request.error);
 };
 });
}
